import * as React from "react"
import { css } from "linaria"
import { customAlphabet } from "nanoid"

const cn = {
  container: css`
    position: relative;
    display: flex;
    justify-content: center;
    height: calc(var(--video-height) + 3rem);
    width: calc(var(--video-width));
    max-width: 100%;
    overflow: hidden;

    input {
      position: absolute;
      visibility: hidden;
    }

    label {
      transition: transform 0.3s;
    }

    [type=radio]:checked ~ label {
      opacity: 1;
      transform: scale(1.7);
    }
  `,

  label: css`
    margin: 0 0.5rem;
    opacity: 0.5;
    color: #fff;
    font-size: 1.2rem;
    line-height: 2.8rem;
    cursor: pointer;
    display: block;
  `,

  switch: css`
    height: 0.7rem;
    cursor: pointer;
  `,

  tab: css`
    padding-bottom: 0.1rem;
  `,

  content: css`
    position: absolute;
    width: 100%;
    left: 0;
    top: 3rem;
    height: 100%;
    display: none;
    text-align: center;

    [type=radio]:checked ~ label ~ & {
      display: block;
    }
  `,

  iframe: css`
    width: var(--video-width);
    height: var(--video-height);
    max-width: 100%;
  `,
  image: css`
    width: var(--video-width);
    height: var(--video-height);
    max-width: 100%;
  `,
}

const nanoid = customAlphabet('1234567890abcdef', 10)

export const Gallery = ({ children }) => {
  const [selected, setSelected] = React.useState(0);
  const onChange = React.useCallback((ev) => {
    setSelected(parseInt(ev.target.value, 10));
  }, [])
  const formId = React.useRef(nanoid())

  return (
    <form className={cn.container}>
      {
        children.map((item, index) => {
          const id = `${formId.current}-${index}`
          const checked = { checked: index === selected ? true : undefined }

          return (
            <div className={cn.tab} key={item.url}>
              <input type="radio" id={id} name="trailer" value={`${index}`} {...checked} onChange={onChange} />
              <label htmlFor={id} className={cn.label}>●</label>
              <div className={cn.content}>
                {item}
              </div>
            </div>
          )
        })
      }
    </form>
  )
}
