import * as React from "react"
import PropTypes from "prop-types"
import { css } from "linaria"

const cn = {
  nominations: css`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5rem;
  `,
  nomination: css``,
  img: css`
    height: 130px;
    width: 130px;
  `
}

export const Badges = (props) => (
  <ul className={cn.nominations}>
    {
      props.badges.map(badge => (
        <li className={cn.nomination} key={`badge_${badge.file}_${badge.title}`}>
          <img className={cn.img} src={badge.file} alt={badge.title} title={badge.title} />
        </li>
      ))
    }
  </ul>
)

Badges.defaultProps = {
  badges: []
}

Badges.propTypes = {
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
}
