import * as React from "react"
import PropTypes from "prop-types"
import { css } from "linaria"
import { Gallery } from "./gallery"

const cn = {
  iframe: css`
    width: var(--video-width);
    height: var(--video-height);
    max-width: 100%;
  `,
}

export const Trailers = ({ trailers }) => {
  return (
    <Gallery>
    {trailers.map(trailer => (
      <iframe
        className={cn.iframe}
        src={trailer.url}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={trailer.title}
      />
    ))}
    </Gallery>
  );
}

Trailers.defaultProps = {
  trailers: [],
}

Trailers.propTypes = {
  trailers: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
}
