import * as React from "react"
import PropTypes from "prop-types"
import { css } from "linaria"

const cn = {
  contacts: css`
    flex-direction: column;
    align-content: center;

    & img {
      transition: transform 0.3s cubic-bezier(.66,2.5,.67,1.08);
    }

    & a:hover img {
      transform: scale(1.2);
    }
  `,
  mainContact: css`
    align-items: center;
    text-align: center;
  `,
  mainContactIcon: css`
    height: 4.6rem;
    margin-left: 3.4rem;
  `,
  nominations: css`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  `,
  contact: css`
    margin: 0.75rem;
  `,
  img: css`
    height: 2.65rem;
  `,
  secondaryContacts: css`
    display: flex;
    justify-content: center;
    margin: 1rem;
  `,
  contact: css`
    margin: 1rem 1.2rem;
  `
}

export const Contacts = (props) => {
  const contacts = [...props.contacts];
  const mainContact = contacts.splice(props.mainContactIndex, 1)[0]
  const mainContactReneder = mainContact !== undefined ? (
    <div className={cn.mainContact}>
      <a href={mainContact.link}>
        <img src={mainContact.file} alt={mainContact.title} className={cn.mainContactIcon} />
      </a>
    </div>
  ) : null

  return (
    <div className={cn.contacts}>
      {mainContactReneder}
      <ul className={cn.secondaryContacts}>
        {
          contacts.map(contact => (
            <li className={cn.contact}>
              <a href={contact.link}>
                <img className={cn.img} src={contact.file} alt={contact.title} title={contact.title} />
              </a>
            </li>
          ))
        }
      </ul>
    </div>
  )
}

Contacts.defaultProps = {
  contacts: [],
  mainContactIndex: 0,
}

Contacts.propTypes = {
  mainContactIndex: PropTypes.number,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
}
