import { Screenshots } from "../../../../src/components/screenshots";
import Screenshot_01 from "../../../../content/main/02-gallery/1_Screenshot.png";
import Screenshot_02 from "../../../../content/main/02-gallery/2_Screenshot.png";
import Screenshot_03 from "../../../../content/main/02-gallery/3_Screenshot.png";
import Screenshot_04 from "../../../../content/main/02-gallery/4_Screenshot.png";
import Screenshot_05 from "../../../../content/main/02-gallery/5_Screenshot.png";
import Screenshot_06 from "../../../../content/main/02-gallery/6_Screenshot.png";
import Screenshot_07 from "../../../../content/main/02-gallery/7_Screenshot.png";
import Screenshot_08 from "../../../../content/main/02-gallery/8_Screenshot.png";
import Screenshot_09 from "../../../../content/main/02-gallery/9_Screenshot.png";
import Screenshot_10 from "../../../../content/main/02-gallery/10_Screenshot.png";
import Screenshot_11 from "../../../../content/main/02-gallery/11_Screenshot.png";
import Screenshot_12 from "../../../../content/main/02-gallery/12_Screenshot.png";
import * as React from 'react';
export default {
  Screenshots,
  Screenshot_01,
  Screenshot_02,
  Screenshot_03,
  Screenshot_04,
  Screenshot_05,
  Screenshot_06,
  Screenshot_07,
  Screenshot_08,
  Screenshot_09,
  Screenshot_10,
  Screenshot_11,
  Screenshot_12,
  React
};