import * as React from "react"
import PropTypes from "prop-types"
import { css } from "linaria"
import { Gallery } from "./gallery"

const cn = {
  image: css`
    width: var(--video-width);
    max-width: 100%;
  `,
}

export const Screenshots = ({ screenshots }) => {
  return (
    <Gallery>
      {screenshots.map(screenshot => (
        <img src={screenshot.file} alt={screenshot.title} className={cn.image} />
      ))}
    </Gallery>
  );
}

Screenshots.defaultProps = {
  screenshots: [],
}

Screenshots.propTypes = {
  screenshots: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
}
