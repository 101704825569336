import { Badges } from "../../../../src/components/badges";
import img0 from "../../../../content/main/04-badges/n_00.png";
import img1 from "../../../../content/main/04-badges/n_01.png";
import img2 from "../../../../content/main/04-badges/n_02.png";
import img3 from "../../../../content/main/04-badges/n_03.png";
import * as React from 'react';
export default {
  Badges,
  img0,
  img1,
  img2,
  img3,
  React
};