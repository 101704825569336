import { Contacts } from "../../../../src/components/contacts";
import discord from "../../../../content/main/05-contacts/icon_discord_text.png";
import fb from "../../../../content/main/05-contacts/icon_fb.png";
import mail from "../../../../content/main/05-contacts/icon_mail.png";
import twitter from "../../../../content/main/05-contacts/icon_twitter.png";
import youtube from "../../../../content/main/05-contacts/icon_youtube.png";
import * as React from 'react';
export default {
  Contacts,
  discord,
  fb,
  mail,
  twitter,
  youtube,
  React
};